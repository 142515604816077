<template>
    <ODropdown v-if="userSession.personId" ref="dropdownRef">
        <template #default="scope">
            <button class="btn btn-link btn-sm" :class="{'o365-active-filter': dataObject.filterObject.activeFilter}" type="button" @click="scope.open" :ref="scope.target" aria-expanded="false">
                <i class="bi bi-funnel-fill"></i>
            </button>
        </template>
        <template #dropdown="scope">
            <div class="card dropdown-menu filter-list-container" :ref="scope.container">
                    <li v-for="item in savedFilters">
                        <div class="d-flex hover-wrapper dropdown-item pe-1" style="width:250px">
                            <button class="btn btn-link p-0 text-decoration-none text-truncate w-100 text-start"
                                @click="openOrApply(item)"
                                :class="{'fw-bold': isActiveItem(item)}"
                                :title="$t(item.Name ?? item.FilterName)">
                                    <i v-if="item.SharedOrgUnit" class="bi bi-share-fill me-1"></i>
                                    <i v-else-if="item.SharedPerson" class="bi bi-person-fill me-1"></i>
                                    {{$t(item.FilterName)}}
                            </button>
                            <ODropdown placement="right">
                                <template #default="{target, open, isOpen}">
                                    <button :ref="target" @click="open" class="btn btn-link btn-sm-2 py-0 show-on-hover" :class="{'d-block': isOpen}">
                                        <i class="bi bi-three-dots-vertical"></i>
                                    </button>
                                </template>
                                <template #dropdown="innerScope">
                                    <div class="dropdown-menu show" :ref="innerScope.container">
                                        <button class="dropdown-item" :title="$t('Rename')"
                                            @click="() => { rename(item); scope.close(); }">
                                            <i class="bi bi-pencil me-1"></i>
                                            {{$t('Rename')}}
                                        </button>
                                        <button class="dropdown-item" :title="$t('Share With Persons')"
                                            @click="() => { activeFilterItem = item; $refs.shareWithPersonsModal.show(); scope.close(); }">
                                            <i class="bi bi-person me-1"></i>
                                            {{$t('Share With Persons')}}
                                        </button>
                                        <button class="dropdown-item" :title="$t('Share With OrgUnits')"
                                            @click="() => {activeFilterItem = item; $refs.shareWithOrgUnitsModal.show(); scope.close(); }">
                                            <i class="bi bi-share me-1"></i>
                                            {{$t('Share With OrgUnits')}}
                                        </button>
                                        <Delete class="dropdown-item" confirm :row="item">
                                            <i class="bi bi-trash me-1"></i>
                                            {{ $t('Delete') }}
                                        </Delete>
                                    </div>
                                </template>
                            </ODropdown>
                            <Delete v-if="!item.Mine && !item.Hidden" :title="$t('Remove shared filter')" confirm :confirmOptions="getConfrimOptions(item)" 
                                :row="item" softDelete class="btn btn-link btn-sm-2 py-0 show-on-hover">
                                <i class="bi bi-x-lg"></i>
                            </Delete>
                        </div>
                    </li>
                <template v-if="sharedFilters.length > 0">
                    <!-- <li><h6 class="dropdown-header">{{$t('Shared Filters')}}</h6></li> -->
                    <li v-for="item in sharedFilters">
                        <div class="d-flex hover-wrapper dropdown-item pe-1" style="width:250px">
                            <button class="btn btn-link p-0 text-decoration-none text-truncate w-100 text-start"
                                @click="openOrApply(item)"
                                :class="{'fw-bold': isActiveItem(item)}"
                                :title="$t(item.Name ?? item.FilterName)">
                                    <i v-if="item.SharedPerson" class="bi bi-person me-1"></i>
                                    <i v-else-if="item.SharedOrgUnit" class="bi bi-share me-1"></i>
                                    {{$t(item.FilterName)}}
                            </button>
                            <Delete :title="$t('Remove shared filter')" confirm :confirmOptions="getConfrimOptions(item)" 
                                :row="item" softDelete class="btn btn-link btn-sm-2 py-0 show-on-hover">
                                <i class="bi bi-x-lg"></i>
                            </Delete>
                        </div>
                    </li>
                </template>

                <template v-if="savedFilters.length === 0 && sharedFilters.length === 0">
                    <div class="text-muted px-2 mx-2">{{ $t('No filters') }}</div>
                </template>

                <!-- <o-filters :data-object="dataObject" :dropdown="scope"></o-filters> -->
          
            </div>
        </template>
    </ODropdown>
    <ShareWithOrgUnitsModal ref="shareWithOrgUnitsModal" :filterItem="activeFilterItem" @hide="activeFilterItem = null" />
    <ShareWIthPersonsModal ref="shareWithPersonsModal" :filterItem="activeFilterItem" @hide="activeFilterItem = null"  noButton/>
</template>

<script setup lang="ts">
import { nextTick, onUnmounted, defineComponent, computed, ref } from 'vue';
import type DataObject from 'o365.modules.DataObject.ts';
import ODropdown from 'o365.vue.components.DropDown.vue'
import Delete from 'o365.vue.components.Action.Delete.vue';
import { userSession } from 'o365.modules.configs.ts';
import $t from 'o365.modules.translate.ts';
import ShareWIthPersonsModal from 'o365.vue.components.Filter.ShareWithPerson.vue';
import ShareWithOrgUnitsModal from 'o365.vue.components.Filter.ShareWithOrgUnits.vue';
import { default as useFieldFilterControls } from 'o365.vue.composables.fieldFilter.ts';
import context from 'o365.modules.Context.ts';



const props = defineProps<{
    dataObject: DataObject,
}>();

const { getFiltersList } = useFieldFilterControls(null, props.dataObject.filterObject);
  
const vContext = ref(context);

const cancelEvent = context.on('Change',(args:any)=>{
    vContext.value = args;
});

const dropdownRef = ref<ODropdown|null>(null);
const activeFilterItem = ref(null);

const savedFilters = computed(() => {
    return getFiltersList(vContext.value).filter(x => x.Mine);
});
const sharedFilters = computed(() => {
    return getFiltersList(vContext.value).filter(x => !x.Mine && !x.Hidden);
});

nextTick(() => {
        if(props.dataObject.filterObject.filtersListDO && !props.dataObject.filterObject.filtersListDO.state.isLoaded && !props.dataObject.filterObject.filtersListDO.state.isLoading) {
            props.dataObject.filterObject.filtersListDO.load();
        }
    })

function openOrApply(pItem) {
    props.dataObject.filterObject.applySavedFilter(pItem);
    if ( dropdownRef.value?.close) {
        dropdownRef.value.close();
    }
}

function isActiveItem(pItem: any) {
    return pItem.FilterName == props.dataObject.filterObject.activeFilter?.FilterName || pItem.FilterName == props.dataObject.filterObject.filterGroup?.filterGroupName
}

function getConfrimOptions(pItem: any) {
    return {
        message: pItem.SharedPerson ? $t('This will remove filter from your list') : $t('Filter will be hidden.'),
        title: pItem.SharedPerson? $t('Remove shared filter') : $t('Hide shared filter'),
        btnTextOk: pItem.SharedPerson ? $t('Remove') : $t('Hide')
    }
}

async function rename(pItem: any) {
    const { default: confirm } = await import('o365.controls.confirm.ts');
    try {
        const result = await confirm({
            message: $t('Name'),
            title: $t('Rename filter'),
            btnTextOk: $t('Save'),
            btnTextCancel: $t('Cancel'),
            initialTextInputValue: pItem.FilterName,
            textInputRequired: true
        });
        const name = result.TextInput;
        if (!name) {
            throw new Error($t(`Filter name can't be empty`));
        }
        pItem.FilterName = name;
        await pItem.save();
    } catch (ex) {
        if (!ex.Canceled) {
            const { default: alert } = await import('o365.controls.alert.ts');
            alert(ex.message);
        }
    }
}
onUnmounted(()=>{
    cancelEvent();
})

</script>

<script lang="ts">

export default defineComponent({
    name: 'OFilterListDropdown'
});
</script>

<style scoped>
.filter-list-container {
    max-width: 250px;
}
.filter-list-container :deep(.dropdown-item:hover .btn.btn-link) {
    color: var(--bs-dropdown-link-hover-color);
}
</style>